<template>
  <div class="panel">
    <div class="container">
      <a
        class="pointer"
        @click="onGoBack"
      >
        <span class="text-left text-white"><i class="el-icon-arrow-left"></i>Back</span>
      </a>
      <span class="text-center text-white">{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isPublic: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true,
    },
  },

  methods: {
    onGoBack() {
      if (window.history.length > 0) {
        this.$router.back()
      }

      if (this.isPublic) {
        this.$router.push({ name: 'public-home' })
      }

      this.$router.push({ name: 'feed-feed', params: { isActiveFilter: this.$route.params.isActiveFilter } })
    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  height: 55px;
  background-color: $feed-detail-banner-bg-color;
  width: 100%;
  .container {
    position: relative;
    .text-left {
      position: absolute;
      line-height: 55px;
    }
    .text-center {
      display: inline-block;
      line-height: 55px;
      width: 100%;
    }
  }
}
</style>